import React, { useEffect, useState,useRef } from 'react';
import WaitingModal from './WaitingModal';
import { Spareka } from './Icon';
const SPAREKACallPlan = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true); // État pour afficher le popup
  const containerRef = useRef(null);
 
 
  // Liste des produits disponibles
  const produits = [
    "Lave-Linge",
    "Réfrigérateur",
    "Aspirateur",
    "Lave-Vaiselle",
    "Blender",
    "Machine à café",
    "Cave à vin",
    "Grille-pain",
    "Hotte",
    "Chauffe-eau",
    "Sèche-Linge",
    "Congélateur",
    "Barbecue",
    "Friteuse",
    "Radiateur",
    "Climatiseur",
    "Four",
    "Plaque de cuisson",
    "Autres",
  ];

  const optionsMapping = {
    "home_appliance_washing_machine": "lave-linge",
    "home_appliance_fridge": "réfrigérateur",
    "small_home_appliance_vacuum_cleaner": "aspirateur",
    "home_appliance_dish_washer": "lave-vaisselle",
    "small_home_appliance_blender": "blender",
    "small_home_appliance_coffee_maker": "machine à café",
    "home_appliance_wine_cellar": "cave à vin",
    "small_home_appliance_toaster": "grille-pain",
    "home_appliance_extractor": "hotte",
    "restroom_bathroom_water_heater": "chauffe-eau",
    "home_appliance_tumble_dryer": "sèche-linge",
    "home_appliance_freezer": "congélateur",
    "autre-univers": "barbecue",
    "small_home_appliance_deep_fryer": "friteuse",
    "autre-univers": "radiateur",
    "small_home_appliance_air_conditioner": "climatiseur",
    "home_appliance_oven": "four",
    "home_appliance_baking_tray": "plaque de cuisson",
    "autre-univers": "autres"
  };
  

  

  const findOptionValue = (prodName) => {
    const result = Object.keys(optionsMapping).find(
      (key) => optionsMapping[key] === prodName
    );
    return result || "Non trouvé";
  };

  const name = product?.prod_name ? product.prod_name.toLowerCase() : "";
    
  const spareka_categ = findOptionValue(name)
  // Affichage du résultat
  console.log(findOptionValue);


  useEffect(() => {
    const iframe = document.createElement('iframe');
    
    
    // URL de l'iframe
    iframe.src = `https://service.spareka.fr/fr/init-widget?api_key=aeca522549315100d0e591a0dbd23c2cc8006c665acc8b024b6222dc13872f78&widget_api_key=e2edf26a1ac55832ec247468073ff4ef2ebf00a5d706ef1c81a0e54d520fdf20&language=fr&category=${spareka_categ}`;
    console.log(iframe.src);
    //iframe.src = `https://lvao.ademe.fr?carte=1&action_list=preter%7Cemprunter%7Clouer%7Cmettreenlocation%7Creparer%7Cdonner%7Cechanger%7Cacheter%7Crevendre&bounding_box=%7B%22southWest%22%3A%7B%22lat%22%3A47.457526%2C%22lng%22%3A-0.609453%7D%2C%22northEast%22%3A%7B%22lat%22%3A47.489048%2C%22lng%22%3A-0.51571%7D%`;
    //`https://lvao.ademe.fr?carte=1&action_list=${actionsEncoded}&action_displayed=${actionsEncoded}&limit=${limit}&bounding_box=${boundingBoxEncoded}&sous_categorie_objet=Lave-linge&bonus=on`;
    
    //https://lvao.ademe.fr?carte=1&action_list=preter%7Cemprunter%7Clouer%7Cmettreenlocation%7Creparer%7Cdonner%7Cechanger%7Cacheter%7Crevendre&bounding_box=%7B%22southWest%22%3A%7B%22lat%22%3A47.457526%2C%22lng%22%3A-0.609453%7D%2C%22northEast%22%3A%7B%22lat%22%3A47.489048%2C%22lng%22%3A-0.51571%7D%
    iframe.id = 'spareka_iframe';
    iframe.style.cssText =
      'width: 100%; height: 100%; border: none; overflow: hidden;';
    iframe.allow = 'geolocation; clipboard-write';
    iframe.allowFullscreen = true;
    iframe.title = 'Spareka Rendez-vous';

    // Ajout de l'événement pour détecter la fin du chargement
    iframe.onload = () => setIsLoading(false);


    // Nettoyage et ajout de l'iframe
    const container = containerRef.current;
    if (container) {
      if (!container.firstChild) {
        container.appendChild(iframe); // Charge seulement si l'iframe n'existe pas
      } else if (container.firstChild.src !== iframe.src) {
        container.replaceChild(iframe, container.firstChild); // Met à jour seulement si l'URL change
      }
    }
  }, []);

  return (
    <>
      {isLoading && <WaitingModal />}
      <div className="mb-3 "
        style={{
          display: "flex", // Active le mode flexbox
          justifyContent: "center", // Centre horizontalement
          alignItems: "center", // Centre verticalement (si nécessaire)
          flexDirection: "column", // Aligne les éléments verticalement
         }}
      >
         <strong>Avec notre partenaire</strong>
        <Spareka></Spareka>
      </div>
      
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: '600px',
          border: '1px solid #ccc',
          position: 'relative',
        }}
      />
    </>
  );
};

export default SPAREKACallPlan;
