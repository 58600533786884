


import axios from 'axios';
import config from './config';

import authHeader from './auth-header';

const API_URL = `${config.serverIP}/${config.apiVersion}`;

class OpenAI {

    // Fonction pour appeler l'API Gateway
    callRAGApi = async (prompt) => {
        
        const URL = "https://fy2rmi610a.execute-api.us-east-1.amazonaws.com/callDcareKB";
    
        try {
        // Effectuer la requête POST avec Axios
        const response = await axios.post(
            URL,
            { user_prompt: prompt }, // Corps de la requête
            {
            headers: {
                "Content-Type": "application/json", // En-têtes
            },
            }
        );
    
        // Retourner la réponse de l'API ou un message par défaut
        return response.data.answer || "Je n'ai pas pu obtenir de réponse, peux-tu reformuler ?";
        } catch (error) {
        console.error("Error calling API:", error);
        return "Je rencontre un problème technique, peux-tu réessayer plus tard ?";
        }
    };
  
    callPanneAssist = async (userQuestion) => {
        try {
            // Construire le payload
            const payload = { userQuestion };
            const URL=API_URL+"/mia/question"
            // Effectuer l'appel API
            const response = await axios.post(URL, payload, { headers: authHeader() });
            console.log("response : ",response)
            console.log("data : ",response.data)
            console.log("reference : ",response.data.reference_urls)
            console.log("reference : ",response.data.reference_urls[0])
            console.log("reference : ",response.data.reference_urls[1])
            console.log("summary : ",response.data.summary)
            // Retourner la réponse JSON
            return response.data;
            
        
            } catch (error) {
            // Gérer les erreurs
            console.error('Error calling panneAssist:', error);
        
            // Si l'API retourne une erreur, gérer la réponse
            if (error.response) {
                return {
                error: error.response.data || 'Une erreur est survenue côté API.',
                };
            }
        
            return { error: 'Une erreur est survenue lors de l\'appel.' };
        }
    };
    /*
    uploadFacture(formData){
        try {
        const response = axios.post('URL_DE_VOTRE_API', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response.data);
        // Gérez la réponse de succès ici
        } catch (error) {
        console.error(error);
        // Gérez l'erreur ici
        }
    }
    */



    async vision(){
        let header = {
            "Authorization" : 'Bearer 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',
            "Content-Type": 'application/json',
        };

        const params = {
            temperature: 0.5,
            max_tokens: 150,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0
        };

        const urimanual="https://api.openai.com/v1/chat/completions";
        const request={
            "model": "gpt-4-vision-preview",
            "messages":[
                {   "role":"system",
                    "content":[
                        {
                            "type": "text",
                            "text":"You will be provided with an image, and your task is to search a speaker in this image then construct response in french with json syntaxe: brand, model exact"
                        }
                    ]
                },
                {
                    "role":"user",
                    "content": [
                        {   "type": "image_url",
                            "image_url": {
                                "url": "https://dcarefile.s3.eu-west-3.amazonaws.com/image/Bose_Home%20Speaker%20500_16927017920164406097cb423c86b.jpg"
                            }
                        }
                    ]
                }
            ],...params
        };

        const response = await axios.post(urimanual,request,{ headers:header});
        const result=response.data.choices[0].message.content;
          // Nettoyer la chaîne pour enlever les éléments de formatage Markdown
        const jsonNettoye = result
        .replace(/```json\n/, '') // Enlève le préfixe ```json\n
        .replace(/\n```/, ''); // Enlève le suffixe \n```
          const objetResultat = JSON.parse(jsonNettoye);
          console.log('response AI : ',objetResultat);
          return objetResultat;
    }
} 


// Créez une instance 
const OpenAIServiceInstance = new OpenAI();

// Exportez l'instance en tant qu'export par défaut
export default OpenAIServiceInstance;



//export default new OpenAI();
