import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/${config.apiVersion}/profils`;

class EventService{


  getEventByUser(){
    const user=JSON.parse(localStorage.getItem('user'));
    const userId=user.id;
    
    let url=`${API_URL}/users/${userId}/events`;

    const headers=authHeader();
    console.log('call event');
    //axios.get(url, { headers, params: req })
    //return axios.get(url,{ headers, pareq });
    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers: authHeader() })
  }

    getEventByAppliance(applianceId){
        const user=JSON.parse(localStorage.getItem('user'));
        const userId=user.id;
        
        let url=`${API_URL}/users/${userId}/appliances/${applianceId}/events`;

        const headers=authHeader();
        console.log('call event', url);
        //axios.get(url, { headers, params: req })
        //return axios.get(url,{ headers, pareq });
        // Effectuer la requête GET avec Axios
        return axios.get(url, { headers: authHeader() })
    }


    saveEvent(deviceId, maintenanceCardId, title,note, type,status,date_realisation) {
        const user=JSON.parse(localStorage.getItem('user'));
        const userId=user.id;
        const currentDate = new Date();
        if (!(date_realisation)) date_realisation=currentDate;
        const req = {
          "applianceId":deviceId,
          "maintenanceCardId":maintenanceCardId,
          "title":title,
          "dt_creation":currentDate,
          "dt_realisation":date_realisation, 
          "note":note,
          "type":type,
          "status":status
        }

        console.log('saveEvent sent ',req);
        
        let url=`${API_URL}/users/${userId}/events`;
        return axios.post(url,req,{ headers: authHeader() });
    }

    saveEventNote = async (eventId, note) => {
      try {
        const user=JSON.parse(localStorage.getItem('user'));
        const userId=user.id;
        const url = `${API_URL}/users/${userId}/events/${eventId}`;
        const response = await axios.put(url, { note },{ headers: authHeader() });
    
        if (response.status === 200) {
          console.log("Event updated successfully:", response.data);
          return response.data; // Retourne l'événement mis à jour
        } else {
          console.error("Failed to update the event.");
          return null;
        }
      } catch (error) {
        console.error("Error occurred while saving event note:", error);
        throw error; // Propagation de l'erreur pour un éventuel traitement supérieur
      }
    };

    //-1 for null value
    saveMaintenanceEvent(dt_creation,dt_planned,title, note, applianceId,maintenanceCardId) {
      
      const user=JSON.parse(localStorage.getItem('user'));
      const userId=user.id;
      let url=`${API_URL}/users/${userId}/events`;

      const req = {
        "title":title,
        "dt_creation":dt_creation,
        "dt_realisation":dt_planned,
        "frequency":"",
        "note":note,
        "type":"MAINTENANCE",
        "status":"NEW",
        "applianceId":applianceId,
        "maintenanceCardId":maintenanceCardId
      }

      console.log('saveevent sent ',req);
    
      return axios.post(url,req,{ headers: authHeader() });
    }

    //-1 for null value
    saveMaintenancePeriodPlanning(dt_creation,frequency,title, note, applianceId,maintenanceCardId) {
      let url=`${API_URL}/users/${userId}/events/period`;

      const user=JSON.parse(localStorage.getItem('user'));
      const userId=user.id;
      
      const req = {
        "title":title,
        "dt_creation":dt_creation,
        "dt_realisation":"",
        "frequency":frequency,
        "note":note,
        "type":"MAINTENANCE",
        "status":"NEW",
        "applianceId":applianceId,
        "maintenanceCardId":maintenanceCardId
      }

      console.log('saveevent sent ',req);
    
      return axios.post(url,req,{ headers: authHeader() });
    }
    
}


// Créez une instance de NotifService
const EventServiceInstance = new EventService();

// Exportez l'instance en tant qu'export par défaut
export default EventServiceInstance;


//export default new EventService();