import React, { useEffect, useState } from "react";
import ADEMEActorMap from "../common/ADEMEActorMap";
import { PageTitle } from "../common/Header";
import { useNavigate, useLocation } from 'react-router-dom';

const CarboMap = () => {
    // Récupérer l'objet univer depuis l'état de location
    const location = useLocation();
    // Récupérer les objets product et event de location.state
    const {product, action } = location.state || {};
    console.log("product", product) 
    console.log("action", action) 
    return (
        <div>
            <PageTitle title="Longue vie aux objets ! La nouvelle carte publiée par l’Ademe" />
            <ADEMEActorMap 
                className="mt-3 mb-3" 
                typeaction={action || "all"} 
                product={product || ""} 
            />
        </div>
    );
};

export default CarboMap;