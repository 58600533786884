import React from 'react';

// Liste des appareils avec leur montant de bonus
const bonusData = [
  { name: 'aspirateurs (balai / robot / traineau)', amount: 40 },
  { name: 'centrale vapeur', amount: 20 },
  { name: 'humidificateur', amount: 15 },
  { name: 'téléphone fixe', amount: 15 },
  { name: 'téléphone portable (casse écran)', amount: 25 },
  { name: 'drone', amount: 20 },
  { name: 'instrument de musique', amount: 15 },
  { name: 'console de jeux', amount: 20 },
  { name: 'imprimante (seuil décl. : 150€)', amount: 35 },
  { name: 'moniteur (seuil décl. : 100€)', amount: 30 },
  { name: 'scanner (seuil décl. : 150€)', amount: 35 },
  { name: 'tablette', amount: 25 },
  { name: 'ordinateur fixe / tout-en-un (seuil décl. : 150€)', amount: 50 },
  { name: 'ordinateur portable (seuil décl. : 150€)', amount: 50 },
  { name: 'amplificateur', amount: 20 },
  { name: 'appareil photo numérique', amount: 20 },
  { name: 'appareil photo reflex', amount: 20 },
  { name: 'téléviseur', amount: 60 },
  { name: 'tuner / démodulateur', amount: 20 },
  { name: 'vidéo-projecteur', amount: 30 },
  { name: 'lecteur dvd', amount: 20 },
  { name: 'lecteur enregistreur audio vidéo', amount: 20 },
  { name: 'table de mixage', amount: 20 },
  { name: 'sèche-linge', amount: 50 },
  { name: 'plaque de cuisson', amount: 25 },
  { name: 'taille haie électrique', amount: 15 },
  { name: 'tondeuse à gazon (et robot)', amount: 15 },
  { name: 'chaine hi-fi', amount: 20 },
  { name: 'enceinte', amount: 20 },
  { name: 'home-cinéma', amount: 20 },
  { name: 'épilateur', amount: 15 },
  { name: 'lisseur', amount: 15 },
  { name: 'plateforme vibrante', amount: 15 },
  { name: 'outillage électroportatif', amount: 20 },
  { name: 'rameur', amount: 15 },
  { name: 'rasoir électrique', amount: 15 },
  { name: 'tapis de course', amount: 15 },
  { name: 'bouilloire', amount: 15 },
  { name: 'centrifugeuse et extracteur', amount: 15 },
  { name: 'grille-pain', amount: 15 },
  { name: 'four micro-ondes', amount: 20 },
  { name: 'four posable', amount: 15 },
  { name: 'friteuse', amount: 15 },
  { name: 'machine à café à capsule', amount: 15 },
  { name: 'machine à café avec filtre', amount: 15 },
  { name: 'machine à café tout automatique', amount: 25 },
  { name: 'presse-agrumes', amount: 15 },
  { name: 'robot multifonctions', amount: 15 },
  { name: 'robot pâtissier', amount: 25 },
  { name: 'robot / préparateur chauffant', amount: 30 },
  { name: 'tondeuse', amount: 15 },
  { name: 'sèche-cheveux', amount: 15 },
  { name: 'vélo d’appartement', amount: 15 },
  { name: 'cave à vin', amount: 25 },
  { name: 'congélateur', amount: 25 },
  { name: 'cuisinière', amount: 25 },
  { name: 'four encastrable (hors micro-ondes)', amount: 25 },
  { name: 'hotte', amount: 25 },
  { name: 'lave-linge', amount: 50 },
  { name: 'lave-vaisselle', amount: 50 },
  { name: 'réfrigérateur', amount: 25 },
  { name: 'réfrigérateur-congélateur', amount: 25 },
  { name: 'défroisseur à main', amount: 15 },
  { name: 'fer à repasser', amount: 15 },
  { name: 'climatiseur mobile', amount: 25 },
  { name: 'nettoyeur vapeur', amount: 20 },
  { name: 'purificateur d’air', amount: 15 },
  { name: 'ventilateur', amount: 15 },
  { name: 'gyroroue', amount: 15 },
  { name: 'hoverboard', amount: 15 },
  { name: 'trottinette électrique', amount: 15 },
  { name: 'vélo à assistance électrique', amount: 15 }
];

// Mapping direct entre produits et bonusData
const produitsBonus = [
  { produit: 'Lave-Linge', mapping: 'lave-linge' },
  { produit: 'Réfrigérateur', mapping: 'réfrigérateur' },
  { produit: 'Aspirateur', mapping: 'aspirateurs (balai / robot / traineau)' },
  { produit: 'Lave-Vaiselle', mapping: 'lave-vaisselle' },
  { produit: 'Blender', mapping: null },
  { produit: 'Machine à café', mapping: ['machine à café à capsule', 'machine à café avec filtre', 'machine à café tout automatique'] },
  { produit: 'Cave à vin', mapping: 'cave à vin' },
  { produit: 'Grille-pain', mapping: 'grille-pain' },
  { produit: 'Hotte', mapping: 'hotte' },
  { produit: 'Chauffe-eau', mapping: null },
  { produit: 'Sèche-Linge', mapping: 'sèche-linge' },
  { produit: 'Congélateur', mapping: 'congélateur' },
  { produit: 'Barbecue', mapping: null },
  { produit: 'Friteuse', mapping: 'friteuse' },
  { produit: 'Radiateur', mapping: null },
  { produit: 'Climatiseur', mapping: 'climatiseur mobile' },
  { produit: 'Four', mapping: ['four micro-ondes', 'four posable', 'four encastrable (hors micro-ondes)'] },
  { produit: 'Plaque de cuisson', mapping: 'plaque de cuisson' },
  { produit: 'Autres', mapping: null }
];

// Fonction pour calculer les montants
export const calculateBonus = (produit) => {
  const entry = produitsBonus.find((item) => item.produit === produit);
  if (!entry || !entry.mapping) return 'Introuvable';

  if (Array.isArray(entry.mapping)) {
    const amounts = entry.mapping
      .map((name) => {
        const match = bonusData.find((item) => item.name === name);
        return match ? match.amount : null;
      })
      .filter((amount) => amount !== null);
    return amounts.length ? `${Math.min(...amounts)} à ${Math.max(...amounts)}` : 'Introuvable';
  } else {
    const match = bonusData.find((item) => item.name === entry.mapping);
    return match ? match.amount : 'Introuvable';
  }
};

const BonusRepair = () => {
  return (
    <div>
      <h1>Bonus Réparation</h1>
      <ul>
        {produitsBonus.map((item, index) => (
          <li key={index}>
            {item.produit} : {calculateBonus(item.produit)} €
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BonusRepair;
