import React, { useEffect, useState } from "react";
import SPAREKACallPlan from "../common/SPAREKACallPlan";
import { PageTitle } from "../common/Header";
import { useNavigate, useLocation } from 'react-router-dom';

const CallPlanning = () => {
    // Récupérer l'objet univer depuis l'état de location
    const location = useLocation();
    // Récupérer les objets product et event de location.state
    const {product} = location.state || {};
    console.log("product", product) 
     return (
        <div>
            <PageTitle title="Prendre rendez-vous" />
            <SPAREKACallPlan 
                className="mt-3 mb-3" 
                product={product || ""} 
            />
        </div>
    );
};

export default CallPlanning;