// config.js

console.log('SERVER_IP CONFIG DOCKER'); // Cette ligne affiche la valeur de SERVER_IP dans la console

export const spareka={ urlVisio: "https://www.spareka.fr/aide-reparation-en-ligne#rdv"};

const config = {
    
    serverIP: "https://dcareapp.fr",
    apiVersion : "api/V1",
    SPAREKA_URL: "https://www.spareka.fr/pieces_detachees_accessoires_electromenager?",

    };
  
  export default config;
  