import React, { useState, useEffect } from "react";
import { Mia,MiaBig } from "../common/Icon";
import OpenAIServiceInstance from "../../services/openai.service";
import { spareka } from "../../services/config";
import { useNavigate } from "react-router-dom";
import WaitingModal from "../common/WaitingModal";
import { calculateBonus } from "../tool/BonusRepair";
const TroubleShootChatbot = ({ product, event }) => {

  const navigate = useNavigate();
  
  const getBonusMessage = (productName) => {
    const bonus = calculateBonus(productName);
    if (bonus === 'Introuvable') {
      return null;
    }
    return { type: "bot", text: `🎉 Super nouvelle ! Ton appareil <strong>"${productName}"</strong> peut bénéficier d'un bonus réparation de <strong>${bonus} €</strong>. 🛠️ Profite-en pour prolonger l'usage de ton appareil en le remettant en parfait état !! 🌱` };
  };

  const bonusMessage = getBonusMessage(product.prodName);

  const [conversation, setConversation] = useState([
    { type: "bot", text: `Coucou 😊, C'est Mia ! Je t’aide à analyser la panne et réparer ton électroménager.<br/> Allons-y 🚀`  },
    { type: "botref", 
        text: `
            <div style="display: flex; align-items: center;">
              <img 
                  src="${product.pict}" 
                  alt="Appareil" 
                  style="max-width: 100px; max-height: 200px; border-radius: 8px; margin-right: 10px;" 
              />
              <div>
                   
                  <strong>${product.prodName}</strong><br/>
                  <strong>Marque :</strong> ${product.marque}<br/>
                  <strong>Modèle :</strong> ${product.modele}
              </div>
            </div>
            ` 
    },
    ...(bonusMessage ? [bonusMessage] : []),
    { type: "bot", text: "Raconte-moi ce qui se passe." },
  ]);
  const [userInput, setUserInput] = useState("");
  const [lastuserInput, setLastUserInput] = useState("");
  const [showInput, setShowInput] = useState(true); // Contrôle de la visibilité du champ de saisie
  const [showOption, setShowOption] = useState(false); // Contrôle de la visibilité du champ de saisie
  const [showYesNo, setShowYesNo] = useState(false); // Contrôle de la visibilité du champ de saisie
  const [isLoading, setIsLoading] = useState(false); //waiting mode

  const handleOptionSelect = (option) => {
    let botResponse = "";

    // Logique basée sur l'option sélectionnée
    if (option === 1) {
      navigate(`/carbomap`, { 
          state: { 
              action: "reparation", 
              product: product 
          } 
      });
    }else if (option === 2) {
      //window.open(spareka.urlVisio, '_blank');
      navigate(`/callplan`, { 
          state: { 
              product: product 
          } 
      });
    } else if (option === 3) {
      
      // Récupérer le dernier message utilisateur
      const lastUserMessage = conversation
        .filter(msg => msg.type === "user")
        .slice(-1)[0].text; // Prendre le dernier
      
      botResponse = 
              "Je vais te guider pour un diagnostic sur le problème: "+lastUserMessage
              +" pour ton appreil "+product.prodName+" "+product.marque+" "+product.modele;
              
      const botWarning = " ⚠️ Petit avertissement: Les infos que je te donne viennent de guides de réparation accessibles en ligne, comme ceux d'iFixit. Pense à toujours aller voir le guide complet pour avoir tous les détails. Je ne peux pas garantir que tout est parfait ni que ça marchera à 100 %. La réparation, c’est à toi de la faire en toute responsabilité, et je ne peux pas être tenu responsable en cas de pépin. Utilise ces infos avec prudence, d'accord ? 😊 ⚠️";
      //set user choice
      setConversation((prev) => [...prev, { type: "user", text: "Diagnostiquer et DIY" }]);
      setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
      setConversation((prev) => [...prev, { type: "bot", text: botWarning }]);
      setShowYesNo(true);
      setShowOption(false);
    }
  };

  const handleYesNoSelect = async(option) => {
    let botResponse = "";

    // Logique basée sur l'option sélectionnée
    if (option === 2) {
      botResponse = "Un choix plein de sagesse ! Se faire aider par un pro, c’est la garantie d’une réparation efficace et sans stress. Je vais te guider pour trouver le bon réparateur ou un coaching adapté. On y va ? 😊"
      setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
      setShowYesNo(false);
      setShowOption(true);
      return
    }else if (option === 1) {
      botResponse = "Top, on est prêts à y aller ! Je vais lancer la recherche de la panne pour te guider au mieux. Accroche-toi, on va trouver une solution ensemble ! 🔍😊"
      const userResponse = "OK, je suis d'accord, on continue !";
      setConversation((prev) => [...prev, { type: "user", text: userResponse }]);
      setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
      setShowYesNo(false);
      setShowOption(false);
      setShowInput(false);

      console.log("call handsend and lastuserInput:", lastuserInput);

      // Utilisation d'une fonction pour garantir la mise à jour correcte
      // Ajout d'une pause avant d'appeler handleSend
      await wait(1000); // Attente de 1 seconde (1000 ms)
      await handleSend();

    }
  }

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const dicoSalutation = [
    // Salutations classiques
    "mia",
    "bonjour",
    "bonsoir",
    "salut",
    "ca va",
    "coucou",
    "hello",
    "hi",
    "allo",
  
    // Questions de politesse
    "ça va",
    "comment ça va",
    "comment vas-tu",
    "comment allez-vous",
    "tout va bien",
    "ça roule",
    "quoi de neuf",
    "bien ou bien",
    "ça gaze",
    "tout baigne",
    "ça farte",
  
    // Formules chaleureuses
    "enchanté",
    "enchantée",
    "ravi de te connaître",
    "ravie de te connaître",
    "heureux de te rencontrer",
    "heureuse de te rencontrer",
    "content de te voir",
    "contente de te voir",
    "heureux de te revoir",
    "heureuse de te revoir",
    "ravi de te revoir",
    "ravie de te revoir",
  
    // Réactions positives
    "quel plaisir de te voir",
    "ça fait plaisir de te voir",
    "heureux de te croiser",
    "heureuse de te croiser",
    "ça fait un bail",
    "longtemps qu’on ne s’est pas vus",
    "ça fait plaisir",
    "content de te retrouver",
    "contente de te retrouver",
    "heureux de te retrouver",
    "heureuse de te retrouver",
  
    // Expressions informelles et amicales
    "yo",
    "hey",
    "wesh",
    "salut toi",
    "bonjour toi",
    "coucou toi",
    "bonjour à toi",
    "salut à toi",
    "bienvenue",
    "bonjour tout le monde",
    "salut tout le monde",
    "hello tout le monde",
    "bonjour à tous",
    "salut à tous",
    "bonjour l’ami",
    "salut l’ami",
  
    // Régionales ou spécifiques
    "bon matin", // Québec
    "bonne journée",
    "bonne soirée",
    "bonne nuit",
    "salutations",
    "mes respects",
    "hola", // Influence espagnole
    "namaste", // Influence asiatique
    "salam", // Influence arabe
    "peace",
  
    // Variantes ou déclinaisons
    "bonjour cher ami",
    "salut cher ami",
    "salut mon pote",
    "bonjour mon pote",
    "salut ma pote",
    "bonjour ma pote",
    "salut mon ami",
    "bonjour mon ami",
    "salut ma chère",
    "bonjour ma chère",
    "bonjour cher",
    "salut cher",
    "salut tout le monde",
    "bonjour la famille",
    "salut la famille",
    "bonjour mes amis",
    "salut mes amis",
    "hello les amis",
    "salut les amis",
    "bonjour les copains",
    "salut les copains",
    "bonjour les filles",
    "salut les filles",
    "bonjour les gars",
    "salut les gars",
  
    // Contextes spécifiques
    "ravie de te voir",
    "heureux de te voir",
    "heureuse de te voir",
    "content de te rencontrer",
    "contente de te rencontrer",
    "heureux de faire ta connaissance",
    "heureuse de faire ta connaissance",
    "content de faire ta connaissance",
    "contente de faire ta connaissance",
  
    // Autres formules amicales
    "ça fait plaisir de te revoir",
    "ça fait du bien de te voir",
    "toujours content de te voir",
    "toujours un plaisir de te voir",
    "quel bonheur de te voir",
    "ça fait chaud au cœur de te voir"
  ];
  

  useEffect(() => {
    // Change le background color de la page
    const previousBackground = document.body.style.backgroundColor; // Sauvegarder la couleur actuelle
    //document.body.style.backgroundColor = '#C4E6D0'; // Appliquer la couleur pour le bot

    // Réinitialiser à la sortie de la page
    return () => {
      document.body.style.backgroundColor = previousBackground; // Restaurer l'ancienne couleur
    };
  }, []);


  const handleSend = async(input) => {
    // Exemple de réponse automatique basée sur la réponse utilisateur
    let botResponse = "";
    console.log("dans handle send avec userInput", userInput);
    
    let currUserInput=userInput;
    
    if (!userInput && !lastuserInput) return;
    else if (!userInput && lastuserInput) currUserInput=lastuserInput
    
    console.log("dans handle send2");
    setLastUserInput(currUserInput);
    
    if(userInput){
      // Ajouter la réponse de l'utilisateur à la conversation
    setConversation((prev) => [...prev,{ type: "user", text: currUserInput}] );
    setUserInput(""); // Réinitialiser le champ de saisie
    }
    

   

    // Si c'est la première entrée de l'utilisateur, proposer les options
    if (conversation.filter((msg) => msg.type === "user").length === 0) {
      botResponse = "Que veux-tu faire ensuite ?"  
      setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
      setShowInput(false); // Cacher le champ de saisie
      setShowOption(true); // Cacher le champ de saisie
      return;
      ;}

    
    
      console.log("dans handle send3");

    // Vérifie si le message ne contient que des salutations (et rien d'autre de significatif)
    function isOnlySalutation(currUserInput) {
      const normalizedInput = currUserInput.toLowerCase().trim();
      return dicoSalutation.some((salutation) =>
        normalizedInput.startsWith(salutation) || normalizedInput.includes(salutation)
      ) && !containsOtherContent(normalizedInput);
    }
    
    // Vérifie si le message contient autre chose que des salutations
    function containsOtherContent(currUserInput) {
      // Suppression des mots de salutation connus
      const cleanedInput = dicoSalutation.reduce(
        (input, salutation) => input.replace(salutation, "").trim(),
        currUserInput
      );
      // Si du texte significatif reste après suppression, ce n'est pas une simple salutation
      return cleanedInput.length > 0;
    }
    
    // Vérifie si une salutation est incluse dans une phrase plus complexe
    function containsSalutation(currUserInput) {
      const normalizedInput = currUserInput.toLowerCase().trim();
      return dicoSalutation.some((salutation) => normalizedInput.includes(salutation));
    }

    

    try{
      if (isOnlySalutation(currUserInput)) {
        botResponse = `Merci ! Décris-moi la panne de <strong>${product.prodName}</strong> pour que je puisse commencer l'analyse.`;
        setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
      } else {
        const question = currUserInput + " sur ma machine " + product.prodName.toLowerCase()
                        
                      //  + " marque "+product.marque
                      //  + " modele "+product.modele
                        + ". "
        console.log("question to be search ", question);
        setIsLoading(true);
        // Appeler l'API pour obtenir la réponse
        botResponse = await OpenAIServiceInstance.callPanneAssist(question);
        // Extraire les URLs
        const urls = botResponse.reference_urls || [];
        const refurls = urls.length > 0 
          ? "Les tutoriels suivants sont intéressants pour ta recherche : <br/>" + 
            urls.map((url) => `<a href="${url}" target="_blank">${url}</a><br/>`).join("")
          : "Je n'ai pas trouvé de tutoriels correspondants pour ta recherche.";

        setConversation((prev) => [...prev, { type: "botref", text: refurls }]);

        // Extraire le résumé
        let summary = botResponse.summary;
         // Tenter de parser le `summary` si c'est une chaîne JSON
         if (typeof summary === "string") {
          summary = summary.replace(/```json|```/g, "").trim(); // Supprimer les backticks
          summary = JSON.parse(summary); // Parser la chaîne JSON propre
        }
          console.log("response summary ", summary)
        // Vérifier si le résumé est bien au format attendu
        if (
          summary
        ) {
           const responseResume = `
            Voici un résumé des étapes clés : <br/>
            <strong>Précautions :</strong> ${summary.PRECAUTION || "NA"}<br/><br/>
            <strong>Causes possibles :</strong>
            <ul>
              ${summary.CAUSE.map((cause) => `<li>${cause}</li>`).join("")}
            </ul>
            <strong>Instructions :</strong>
            <ul>
              ${summary.INSTRUCTION.map((step) => `<li>${step}</li>`).join("")}
            </ul>
            N'hésite pas à aller voir le tutoriel : 
            <a href="${urls[0]}" target="_blank">${urls[0]}</a><br/>
          `;
        
          setConversation((prev) => [...prev, { type: "botref", text: responseResume }]);
              //setConversation((prev) => [...prev, { type: "bot", text: botResponse }]);
          setIsLoading(false);
          setShowInput(true);
        }
      } 
    }catch (error) {
        // Gérer les erreurs imprévues
        console.error("Erreur lors du traitement de la réponse :", error);
        setConversation((prev) => [
          ...prev,
          { type: "bot", text: "Une erreur est survenue lors de l'analyse des tutoriels." },
        ]);
        setIsLoading(false);
        setShowInput(true);
    }
    setIsLoading(false);
    setShowInput(true);
  };


//https://service.spareka.fr/fr/init-widget?api_key=aeca522549315100d0e591a0dbd23c2cc8006c665acc8b024b6222dc13872f78&widget_api_key=e2edf26a1ac55832ec247468073ff4ef2ebf00a5d706ef1c81a0e54d520fdf20&language=fr&category=home_appliance_washing_machine&extra_fields=%7B%22device_type_code%22%3A%22a0771b0d%22%7D&options%5BhideCategorySelection%5D=1&fromUrl=https%3A%2F%2Fwww.spareka.fr%2Faide-reparation-en-ligne
  return (
    <div
      className="mb-4 "
    >
     <div className="mb-3 "
        style={{
          display: "flex", // Active le mode flexbox
          justifyContent: "center", // Centre horizontalement
          alignItems: "center", // Centre verticalement (si nécessaire)
         }}
      >
        <MiaBig
          className="boxShadow mb-3"
          style={{
            width: "200px",
            height: "auto", // Maintient les proportions de l'image
            flexShrink: 0, // Empêche l'image de rétrécir
          }}
        />
      </div>

      {/* Zone de conversation */}
      <div style={{ 
            maxHeight: "100%", overflowY: "auto", marginBottom: "20px" }}>

        {conversation.map((msg, index) => (
         <div
            key={index}
            style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            
            }}
        >
            {/* Avatar */}
            {msg.type === "bot" && index > 0 && (
            <Mia className="boxShadow"
                style={{
                width: "40px",
                height: "40px",   
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                flexShrink: 0, // Empêche l'avatar de rétrécir
                }}
            />
            )}
        
            {/* Message */}
            <div 
            className="d-flex align-items-center text-small boxShadow"
            style={{
              backgroundColor: 
                msg.type === "bot" ? 'var(--color-light-green)' :
                msg.type === "botref" ? '' :
                'var(--color-light-gray)', // Par défaut pour "user"
              border: 
                msg.type === "botref" ? '1.5px solid var(--color-green)':
                '',
              borderRadius: 
                msg.type === "bot" ? "0px 15px 15px 15px" :
                msg.type === "user" ? "15px 15px 0px 15px" :
                "15px",
              alignItems: "center",
              color: (msg.type === "bot" || msg.type === "botref") ? "#000" : "#0b5133",
              textAlign: (msg.type === "bot" || msg.type === "botref") ? "left" : "right",
              flex: 1, // Prend toute la place restante
            }}
            >
             {/* Rendu du texte avec HTML */}
            <div 
              dangerouslySetInnerHTML={{ __html: msg.text }} 
            />
            <style>
              {`
                a {
                  overflow-wrap: anywhere;
                  }
              `}
            </style>
            </div>
        </div>
        ))}
       {showOption && (
          <div className="d-flex text-medium"
             style={{
              justifyContent: "space-between",
              gap: "10px", // espace entre les blocs
              textAlign: "center", // centrer le texte
            }}
          >
            <div
              onClick={() => handleOptionSelect(1)}
              style={{
                cursor : "pointer",
                padding: "8px",
                backgroundColor: 'var(--color-green)',
                color: "#fff",
                borderRadius: "5px",
                flex:1,
                display: "flex",
                alignItems: "center", // Centrage vertical
                justifyContent: "center", // Optionnel si tu veux aussi centrer horizontalement
              }}
            >
              Chercher un réparateur
            </div>
            <div
              onClick={() => handleOptionSelect(2)}
              style={{
                cursor : "pointer",
                padding: "8px",
                backgroundColor: 'var(--color-green)',
                color: "#fff",
                borderRadius: "5px",
                border: "none",
                flex:1,
                display: "flex",
                alignItems: "center", // Centrage vertical
                justifyContent: "center", // Optionnel si tu veux aussi centrer horizontalement
              }}
            >
              Faire aider en visio
            </div>
            <div
              onClick={() => handleOptionSelect(3)}
              style={{
                cursor : "pointer",
                padding: "8px",
                backgroundColor: 'var(--color-green)',
                color: "#fff",
                borderRadius: "5px",
                flex:1,
                display: "flex",
                alignItems: "center", // Centrage vertical
                justifyContent: "center", // Optionnel si tu veux aussi centrer horizontalement
              }}
            >
              Diagnostic et auto-réparation assistée
            </div>
          </div>
        )}

        {showYesNo && (
          <div className="d-flex text-medium"
             style={{
              justifyContent: "space-between",
              gap: "10px", // espace entre les blocs
              textAlign: "center", // centrer le texte
            }}
          >
            <div
            className="d-flex green-border"
              onClick={() => handleYesNoSelect(1)}
              style={{
                cursor : "pointer",
                padding: "8px",
                borderRadius: "5px",
                flex:1,
                display: "flex",
                alignItems: "center", // Centrage vertical
                justifyContent: "center", // Optionnel si tu veux aussi centrer horizontalement
              }}
            >
              OK, je suis d'accord, on continue !
            </div>
            <div
              onClick={() => handleYesNoSelect(2)}
              style={{
                cursor : "pointer",
                padding: "8px",
                backgroundColor: 'var(--color-green)',
                color: "#fff",
                borderRadius: "5px",
                border: "none",
                flex:1,
                display: "flex",
                alignItems: "center", // Centrage vertical
                justifyContent: "center", // Optionnel si tu veux aussi centrer horizontalement
              }}
            >
              Je préfère me faire aider par un pro pour être tranquille.
            </div>
          </div>
        )}

      </div>

      {/* Saisie utilisateur */}
      {showInput && ( <div className="d-flex text-small">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Écris ton message ici..."
          style={{ flex: 1, padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
        />
        <div
          onClick={handleSend}
          style={{
            cursor : "pointer",
            padding: "8px",
            backgroundColor: 'var(--color-green)',
            color: "#fff",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
        >
          Envoyer
        </div>
      </div>
    )}

    {isLoading && <WaitingModal />}
    </div>
  );
};

export default React.memo(TroubleShootChatbot);
